import { useState } from "react";
import MainLayout from "../../layouts"
import Paper from '@mui/material/Paper';
import { Alert, Snackbar } from "@mui/material"
import { DataGrid } from '@mui/x-data-grid';
import { useEffect } from "react";
import { getVehicles } from "../../utils/api"
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import "./vehicles.scss"
import EditionModal from "../../components/Vehicles/EditionModal";


function VehiclesPage() {
    const [vehicles, setVehicles] = useState([])
    const [editionModalOpen, setEditionModalOpen] = useState(false)
    const [selectedVehicle, setSelectedVehicle] = useState({})
    const [openAlert, setOpenAlert] = useState(false)
    const [alertData, setAlertData] = useState({ message: "", type: "", })

    const columns = [
        { field: 'name', headerName: 'Media Outlet', flex: true },
        { field: 'editorship', headerName: 'Editorship', flex: true },
        {
            field: 'audience',
            headerName: 'Audience',
            flex: true,

        },
        { field: 'vehicle_type', headerName: 'Vehicle Type', flex: true },
        { field: 'location', headerName: 'Location', flex: true },
        { field: 'price', headerName: 'Price', flex: true },
        {
            field: 'edit',
            headerName: 'Ações',
            width: 100,
            renderCell: (params) => (
                <>
                    <IconButton
                        color="tertiary"
                        onClick={() => { openEditionModal(params) }}
                    >
                        <EditIcon />
                    </IconButton>
                    {/* <IconButton
                        color="tertiary"
                        onClick={() => console.log(params)}
                    >
                        <DeleteIcon />
                    </IconButton> */}
                </>
            ),
        },
    ];

    const paginationModel = { page: 0, pageSize: 10 };

    const openEditionModal = (params) => {
        setSelectedVehicle(params["row"])
        setEditionModalOpen(true)
    }

    const closeEditionModal = () => {
        setSelectedVehicle({})
        setEditionModalOpen(false)
    }

    const fetchData = () => {
        getVehicles().then(({ data }) => setVehicles(data)).catch(err => console.log(err))
    }

    const showAlert = (message, type) => {
        setAlertData({ message, type })
        setOpenAlert(true)
    }

    const handleCloseAlert = () => {
        setOpenAlert(false)
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <MainLayout>
            <Paper elevation={5} sx={{ height: "fit-content", width: '100%' }}>
                <DataGrid
                    rows={vehicles}
                    columns={columns}
                    initialState={{ pagination: { paginationModel } }}
                    pageSizeOptions={[5, 10]}
                    autoHeight={true}
                    sx={{ border: 0 }}
                />
            </Paper>
            <EditionModal open={editionModalOpen} close={closeEditionModal} selectedVehicle={selectedVehicle} refreshData={fetchData} showAlert={showAlert} />
            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                <Alert
                    onClose={handleCloseAlert}
                    severity={alertData.type}
                    variant="filled"
                >
                    {alertData.message}
                </Alert>
            </Snackbar>
        </MainLayout>
    )
}

export default VehiclesPage